import { every, toNumber } from 'lodash';

import { isNonEmptyString } from '../../../../../../libs/utils/src/isNonEmptyString';
import { DEFAULT_PREVIOUS_RESIDENCE_MONTHS_AT } from '../../applicantStore/constants/residence';
import { ResidenceTypeEnum } from '../../applicantStore/enums/ResidenceTypeEnum';
import { residenceTypeToOwnershipStatus } from '../../applicantStore/functions/residenceTypeToOwnershipStatus';
import { CoapplicantResidencesDto } from '../dto/CoapplicantResidencesDto';
import { CoapplicantState } from '../types/CoapplicantState';

export const toCoapplicantResidencesDto = (
  state: CoapplicantState,
): CoapplicantResidencesDto => {
  const coapplicantResidencesDto: CoapplicantResidencesDto = {
    current: {
      monthlyPaymentAmount: toNumber(
        state.coapplicantCurrentResidenceMonthlyPaymentAmount,
      ),
      monthsAtResidence: toNumber(state.coapplicantCurrentResidenceMonthsAt),
      ownershipStatus: residenceTypeToOwnershipStatus(
        state.coapplicantCurrentResidenceType,
      ),
      physicalAddressCity: state.coapplicantCurrentResidenceCity || '',
      physicalAddressLineOne: state.coapplicantCurrentResidenceLineOne || '',
      physicalAddressLineTwo: state.coapplicantCurrentResidenceLineTwo,
      physicalAddressPostalCode:
        state.coapplicantCurrentResidencePostalCode || '',
      physicalAddressProvince: state.coapplicantCurrentResidenceState || '',
    },
    previous: null,
  };

  const shouldIncludePrevious = every(
    [
      state.coapplicantPreviousResidenceLineOne,
      state.coapplicantPreviousResidenceCity,
      state.coapplicantPreviousResidencePostalCode,
      state.coapplicantPreviousResidenceState,
    ],
    isNonEmptyString,
  );

  if (shouldIncludePrevious) {
    coapplicantResidencesDto.previous = {
      monthlyPaymentAmount: 0,
      monthsAtResidence: DEFAULT_PREVIOUS_RESIDENCE_MONTHS_AT,
      ownershipStatus: residenceTypeToOwnershipStatus(ResidenceTypeEnum.OTHER),
      physicalAddressCity: state.coapplicantPreviousResidenceCity || '',
      physicalAddressLineOne: state.coapplicantPreviousResidenceLineOne || '',
      physicalAddressLineTwo: state.coapplicantPreviousResidenceLineTwo,
      physicalAddressPostalCode:
        state.coapplicantPreviousResidencePostalCode || '',
      physicalAddressProvince: state.coapplicantPreviousResidenceState || '',
    };
  }

  return coapplicantResidencesDto;
};

import { get as lodashGet } from 'lodash';
import { StateCreator } from 'zustand';

import { HttpStatusCode } from '../../common';
import { ApiState } from './types/ApiState';
import { ApiStateDependencies } from './types/ApiStateDependencies';

const loadingTransitionTime = 1200; // ms

export const apiStore =
  ({
    config,
    httpClient,
    useParameterStore,
    useSignOutStore,
  }: ApiStateDependencies): StateCreator<ApiState> =>
  (set, get) => ({
    isLoading: false,
    // isLoadingTransition is used to prevent flickering of loading state
    // when a request is resolved too quickly
    isLoadingTransition: false,

    handleUnauthorized: (error: unknown) => {
      const statusCode = lodashGet(error, 'response.status');

      const { token } = useParameterStore.getState();
      const { isSigningOut, signOut } = useSignOutStore.getState();

      if (
        statusCode === HttpStatusCode.UNAUTHORIZED &&
        token &&
        !isSigningOut // ensure the signout process is not already in progress
      ) {
        signOut({
          withAuthRedirectPath: window.location.pathname,
        });
      }
    },
    httpRequest: () => httpClient.create(),
    portalApi: () => {
      const portalHttpRequest = httpClient.create({
        baseURL: new URL(config.api.path, config.api.baseUrl).href,
        headers: {},
        withCredentials: true,
      });

      portalHttpRequest.interceptors.request.use((requestConfig) => {
        set({ isLoading: true, isLoadingTransition: true });

        const { token } = useParameterStore.getState();

        if (token && requestConfig?.headers) {
          requestConfig.headers['X-Access-Token'] = token;
        }
        return requestConfig;
      });

      portalHttpRequest.interceptors.response.use(
        (response) => {
          set({ isLoading: false });

          setTimeout(
            () => set({ isLoadingTransition: false }),
            loadingTransitionTime,
          );

          const {
            data: { token, ...data },
          } = response;

          if (token) {
            useParameterStore.setState({ token });
          }

          return response.data || data;
        },
        (error) => {
          set({ isLoading: false });

          setTimeout(
            () => set({ isLoadingTransition: false }),
            loadingTransitionTime,
          );

          get().handleUnauthorized(error);

          return Promise.reject(error);
        },
      );

      return portalHttpRequest;
    },
  });

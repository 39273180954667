import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { dashboardStore } from './dashboardStore';
import { DashboardState } from './types/DashboardState';
import { DashboardVehicle } from './types/DashboardVehicle';

const useDashboardStore: DevtoolsStore<DashboardState<DashboardVehicle>> =
  create()(
    devtools(
      dashboardStore({
        useApiStore: lazyDependency(() => useApiStore),
        Vehicle: DashboardVehicle,
      }),
      devToolOptions('dashboardStore'),
    ),
  );

export default useDashboardStore;

import { filter, find, get, join } from 'lodash';

import {
  ApplicationType,
  isApplicationActive,
  LOS_CANCELLED_STATUSES,
  LOS_LEGACY_STATUSES,
} from '../../../common';
import { ProgressStep } from '../../../services/applicationProgress/applicationProgress';
import {
  getPreviewStepsByApplicationStatus,
  getStepsByApplicationStatus,
} from '../../../services/applicationProgress/applicationProgressService';
import { Task } from '../../../services/applicationTask/applicationTask';
import { getTaskByApplicationStatus } from '../../../services/applicationTask/applicationTaskService';
import { inputEventHandler } from '../../../utils/component/inputEventHandler';

export const DEFAULT_BRAND = 'AUTOPAY';
export const DEFAULT_DESCRIPTION = 'Your vehicle';

export const DEFAULT_APPLICATION_EMPLOYEE: Record<string, any> = {
  AUTOPAY: {
    emailAddress: 'save@autopay.com',
    name: 'AUTOPAY',
    phoneNumber: '8442763272',
  },
  RateGenius: {
    emailAddress: 'save@rategenius.com',
    name: 'RateGenius',
    phoneNumber: '8667283436',
  },
  Tresl: {
    emailAddress: 'save@mytresl.com',
    name: 'Tresl',
    phoneNumber: '8446075252',
  },
};

type Application = {
  applicationOwner?: ApplicationOwner | null;
  brand?: string;
  hasCoApplicant?: boolean;
  id?: string;
  leadChannelCode?: string;
  sortingDate?: string;
  source?: string;
  sourceApplicationId?: string;
  sourceStatus?: string | null;
  status?: string;
  type?: ApplicationType;
};

type ApplicationOwner = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
};

export class DashboardVehicle {
  public application: Application | null = null;
  public id = '';
  public images: { type: string; url: string }[] = [];
  public make = '';
  public model = '';
  public year = 0;

  public constructor(
    vehicle?: DashboardVehicle | Partial<DashboardVehicle> | null,
  ) {
    if (vehicle) {
      Object.assign(this, vehicle);
    }
  }

  public get applicationEmployeeName(): string {
    const applicationOwnerFirstName = get(
      this,
      'application.applicationOwner.firstName',
      '',
    ) as string;
    const applicationOwnerLastName = get(
      this,
      'application.applicationOwner.lastName',
      '',
    ) as string;

    if (!applicationOwnerFirstName || !applicationOwnerLastName) {
      return DEFAULT_APPLICATION_EMPLOYEE[this.brand].name;
    }

    return `${applicationOwnerFirstName} ${applicationOwnerLastName.charAt(
      0,
    )}.`;
  }

  public get applicationEmployeeEmail(): string {
    return get(
      this,
      'application.applicationOwner.emailAddress',
      DEFAULT_APPLICATION_EMPLOYEE[this.brand].emailAddress,
    );
  }

  public get applicationEmployeePhone(): string {
    const applicationOwnerPhoneNumber = get(
      this,
      'application.applicationOwner.phoneNumber',
      DEFAULT_APPLICATION_EMPLOYEE[this.brand].phoneNumber,
    );

    return inputEventHandler.phoneNumber.formatter(applicationOwnerPhoneNumber);
  }

  public get applicationDescription(): string {
    if (this.isInactive || !this.applicationId) {
      return this.brand;
    }

    return `${this.brand} #${this.applicationId}`;
  }

  public get applicationId(): string | undefined {
    return this.application?.sourceApplicationId?.substring(6);
  }

  public get applicationTask(): Task {
    const sourceStatus = get(this, 'application.sourceStatus', '') as string;

    return getTaskByApplicationStatus(sourceStatus);
  }

  public get applicationProgress(): ProgressStep[] {
    const status = get(this, 'application.sourceStatus', '') as string;

    return getStepsByApplicationStatus(status);
  }

  public get applicationProgressPreview(): ProgressStep[] {
    const status = get(this, 'application.sourceStatus', '') as string;

    return getPreviewStepsByApplicationStatus(status);
  }

  public get brand(): string {
    return this.application?.brand || DEFAULT_BRAND;
  }

  public get defaultImage(): string | undefined {
    return find(this.images, (image) => image.type === 'DEFAULT')?.url;
  }

  public get description(): string {
    const desc = filter([this.year, this.make, this.model], Boolean);

    return desc.length > 1 ? join(desc, ' ') : DEFAULT_DESCRIPTION;
  }

  public get isInactive(): boolean {
    const sourceStatus = get(this, 'application.sourceStatus', '') as string;

    // prefer using the sourceStatus if it exists
    // and is one of the statuses we know are inactive
    if (sourceStatus) {
      return (
        LOS_CANCELLED_STATUSES.includes(sourceStatus) ||
        LOS_LEGACY_STATUSES.includes(sourceStatus)
      );
    }

    // otherwise, check the status we have in Radix
    const status = get(this, 'application.status', '');

    if (status) {
      // @ts-expect-error - expects a full object, we just need the status
      return !isApplicationActive({ status });
    }

    return false;
  }

  public get isRefandiType(): boolean {
    return this.application?.type === ApplicationType.RE_F_AND_I;
  }

  public get yearMakeModel(): string {
    return `${this.year} ${this.make} ${this.model}`;
  }
}

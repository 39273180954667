import { splitFilename } from '@utils/splitFilename';
import { get, isEmpty } from 'lodash';

import { Workflow } from '../../constants/Workflow';
import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { DocumentFile, DOCUMENTS_FILE_CONFIG } from './documentFileConfig';
import {
  DocumentEnum,
  DOCUMENTS_CONFIG,
  REQUIRED_DOCUMENTS_LIST,
} from './documentsConfig';

export const getDocumentsConfig = (docs: DocumentEnum[]) =>
  docs.map((doc) => DOCUMENTS_CONFIG[doc]);

export const getRequiredDocuments = (
  workflowName: Workflow,
  state?: ApplicationDataInterface,
): DocumentEnum[] => {
  const workflowDocuments = REQUIRED_DOCUMENTS_LIST[workflowName];

  return workflowDocuments
    .filter((doc) => {
      if (typeof doc === 'object' && doc.cond) {
        return state && doc.cond(state);
      }
      return true;
    })
    .map((doc) => (typeof doc === 'object' ? doc.documentName : doc));
};

export const getRequiredDocumentsToCompleteApplication = (
  documents: DocumentEnum[],
  optionalDocuments: DocumentEnum[],
): DocumentEnum[] =>
  documents.filter((doc) => !optionalDocuments.includes(doc));

const FILE_EXTENSION_REGEX = /\.[0-9a-z]+$/i;

export const isDocumentOptional = (doc: DocumentEnum) => {
  return !DOCUMENTS_CONFIG[doc]?.optional;
};

export const isUploaded = (uploadedAssets: any[], uploadTask: DocumentEnum) => {
  const comparator = ({ documentSubtypeName, assetDescription }: any) =>
    documentSubtypeName === 'Other'
      ? assetDescription.split(FILE_EXTENSION_REGEX)[0]
      : documentSubtypeName;

  return DOCUMENTS_CONFIG[uploadTask]?.documents.every((doc: DocumentFile) =>
    uploadedAssets.some(
      (asset) => comparator(asset) === comparator(DOCUMENTS_FILE_CONFIG[doc]),
    ),
  );
};

export const getUploadedDocuments = (
  requiredAssetsList: DocumentEnum[],
  uploadedAssets: any[],
) => {
  return requiredAssetsList.filter((doc) => isUploaded(uploadedAssets, doc));
};

export type FilesPayload = {
  file: File;
  metadata: {
    assetDescription: string;
    autopayNumber: string;
    contentType: string;
    documentName: string;
    documentSubType: string;
    documentSubtypeName: string;
    uniqueIdentifier?: string;
  };
  uploadIdentifier: string;
};

export const getRandomValue = (): string => {
  try {
    // pretty decent browser support for crypto, but just in case it fails
    // we can fallback to a generic version using Math.random that will produce
    // a "random enough" value
    return window.crypto.getRandomValues(new Uint8Array(4)).join('');
  } catch (error) {
    return Math.random().toString(36).substring(2, 5);
  }
};

export const createFilesPayload = (
  formData: { [key: string]: { metadata: File } },
  autopayNumber: string,
  uniqueIdentifier?: string,
): FilesPayload[] => {
  return Object.keys(formData).map((key) => {
    const {
      assetDescription,
      documentSubtypeName,
      documentSubType,
      uploadIdentifier,
    } = DOCUMENTS_FILE_CONFIG[key as DocumentFile];

    const file = get(formData, [key, 'metadata']);
    const contentType = get(formData, [key, 'metadata', 'type']);

    // documentName needs to have a random value injected so we can support
    // multiple files with the same name
    const documentName = addRandomValueToFilename(
      get(formData, [key, 'metadata', 'name']),
    );

    return {
      file,
      metadata: {
        assetDescription,
        autopayNumber,
        contentType,
        documentName,
        documentSubType,
        documentSubtypeName,
        uniqueIdentifier,
      },
      uploadIdentifier,
    };
  });
};

export const addRandomValueToFilename = (filename: string): string => {
  if (isEmpty(filename)) {
    return '';
  }

  if (!filename.includes('.')) {
    return `${filename}-${getRandomValue()}`;
  }

  const { name, extension } = splitFilename(filename);
  return `${name}-${getRandomValue()}.${extension}`;
};

export const truncateFilename = (
  filename: string,
  maxFilenameLength: number,
): string => {
  if (isEmpty(filename) || maxFilenameLength === 0) {
    return '';
  }

  if (filename.length <= maxFilenameLength) {
    return filename;
  }

  const { name, extension } = splitFilename(filename);

  if (name.length <= maxFilenameLength || maxFilenameLength <= 4) {
    return `${name.substring(0, maxFilenameLength)}.${extension}`;
  }

  return `${name.substring(0, maxFilenameLength - 3)}...${extension}`;
};

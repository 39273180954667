import { splitFilename } from '@utils/splitFilename';

import { config } from '../../../config';
import { HttpClientInstance } from '../../apiStore/types/HttpClientInstance';
import { LoanDocumentType } from '../types/LoanDocumentType';
import { LoanSignedUrlResponseType } from '../types/LoanSignedUrlResponseType';
import { reportURLError } from './reportURLError';

export const getSignedUrl =
  ({
    getFileName,
    portalHttpRequest,
  }: {
    getFileName: (file: File) => string;
    portalHttpRequest: HttpClientInstance;
  }) =>
  (
    document: LoanDocumentType,
    userId: string,
    count: number,
  ): Promise<LoanSignedUrlResponseType> => {
    const { extension } = splitFilename(document.file.name);

    const originalFileName = getFileName(document.file);

    return portalHttpRequest
      .post<never, LoanSignedUrlResponseType>(
        `/2.0/documents/loan-signed-url`,
        {
          contentType: document.file.type,
          count,
          documentType: document.type,
          extension,
          originalFileName,
          userId,
        },
        config.loanDocuments.signedUrlConfig,
      )
      .catch(reportURLError(document));
  };

import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useDashboardStore from '../dashboardStore/useDashboardStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import useUserStore from '../userStore/useUserStore';
import { getVerifications } from './functions/getVerifications';
import { VerificationState } from './types/VerificationState';
import { verificationStore } from './verificationStore';

const useVerificationStore: DevtoolsStore<VerificationState> = create({
  hmrPersist: 'verificationStore',
})(
  devtools<VerificationState>(
    verificationStore({
      getVerifications,
      useApiStore: lazyDependency(() => useApiStore),
      useDashboardStore: lazyDependency(() => useDashboardStore),
      useUserStore: lazyDependency(() => useUserStore),
    }),
    devToolOptions('verificationStore'),
  ),
);

export default useVerificationStore;

import { NO, YES } from '../../applicantStore/types/YesOrNo';
import useApplicantStore from '../../applicantStore/useApplicantStore';
import useCoapplicantStore from '../../coapplicantStore/useCoapplicantStore';
import useDashboardStore from '../../dashboardStore/useDashboardStore';
import { WorkflowPage } from '../types/WorkflowPage';

export const applicantWorkflow: WorkflowPage[] = [
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/residence-type`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/residence-details`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/residence-duration`;
    },
  },
  {
    condition() {
      const {
        computed: { isCurrentResidenceLessThan2Years },
      } = useApplicantStore.getState();

      return isCurrentResidenceLessThan2Years;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/previous-residence`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-status`;
    },
  },
  {
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-type`;
    },
  },
  {
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === NO;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/unemployment-type`;
    },
  },
  {
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-details`;
    },
  },
  {
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === NO;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/unemployment-details`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-duration`;
    },
  },
  {
    condition() {
      const {
        computed: { isCurrentIncomeLessThan1Year },
      } = useApplicantStore.getState();

      return isCurrentIncomeLessThan1Year;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/previous-employment`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/additional-income`;
    },
  },
  {
    condition() {
      const { hasAdditionalIncome } = useApplicantStore.getState();
      return hasAdditionalIncome === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/income-details`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/add-coapplicant`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-personal-relationship`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-personal-details`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-residence-relationship`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { hasSameResidenceAsApplicant } = useCoapplicantStore.getState();
      return addCoapplicant === YES && hasSameResidenceAsApplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-same-residence`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { hasSameResidenceAsApplicant } = useCoapplicantStore.getState();
      return addCoapplicant === YES && hasSameResidenceAsApplicant === NO;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-different-residence-type`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { hasSameResidenceAsApplicant } = useCoapplicantStore.getState();
      return addCoapplicant === YES && hasSameResidenceAsApplicant === NO;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-different-residence`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-residence-duration`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const {
        computed: { isCurrentResidenceLessThan2Years },
      } = useCoapplicantStore.getState();

      return addCoapplicant === YES && isCurrentResidenceLessThan2Years;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-previous-residence`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-employment-status`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { isCoapplicantCurrentlyEmployed } = useCoapplicantStore.getState();
      return addCoapplicant === YES && isCoapplicantCurrentlyEmployed === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-employment-type`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { isCoapplicantCurrentlyEmployed } = useCoapplicantStore.getState();
      return addCoapplicant === YES && isCoapplicantCurrentlyEmployed === NO;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-unemployment-type`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { isCoapplicantCurrentlyEmployed } = useCoapplicantStore.getState();
      return addCoapplicant === YES && isCoapplicantCurrentlyEmployed === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-employment-details`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const { isCoapplicantCurrentlyEmployed } = useCoapplicantStore.getState();
      return addCoapplicant === YES && isCoapplicantCurrentlyEmployed === NO;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-unemployment-details`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-employment-duration`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      const {
        computed: { isCurrentIncomeLessThan1Year },
      } = useCoapplicantStore.getState();

      return addCoapplicant === YES && isCurrentIncomeLessThan1Year;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-previous-employment`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-additional-income`;
    },
  },
  {
    condition() {
      const { hasAdditionalIncome } = useCoapplicantStore.getState();
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES && hasAdditionalIncome === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-income-details`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/review-applicant`;
    },
  },
  {
    condition() {
      const { addCoapplicant } = useApplicantStore.getState();
      return addCoapplicant === YES;
    },
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-review-applicant`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/submit-application`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/application-success`;
    },
  },
];

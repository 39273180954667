import { get as lodashGet } from 'lodash';
import { StateCreator } from 'zustand';

import { LeadChannelState } from './types/LeadChannelState';
import { LeadChannelStateDependencies } from './types/LeadChannelStateDependencies';

export const leadChannelStore =
  ({
    useApiStore,
  }: LeadChannelStateDependencies): StateCreator<LeadChannelState> =>
  (set, get) => ({
    initLeadChannelFinished: false,
    leadChannel: {},

    computed: {
      get displayLabel() {
        const leadChannel = get().leadChannel;

        return lodashGet(leadChannel, 'displayLabel', '');
      },
      get primaryLogoURL() {
        const leadChannel = get().leadChannel;

        return lodashGet(leadChannel, 'primaryLogo.url', '');
      },
    },

    initLeadChannel: (leadChannelCode: string | null | undefined) => {
      if (!leadChannelCode) {
        set({ initLeadChannelFinished: true });
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get(`/ui-configuration/lead-channel/${leadChannelCode}`)
        .then((leadChannel) => {
          set({
            leadChannel,
          });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          set({ initLeadChannelFinished: true });
        });
    },
  });

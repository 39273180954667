import { isNonEmptyString } from '@utils/isNonEmptyString';
import { every, toNumber } from 'lodash';

import { DEFAULT_PREVIOUS_INCOME_MONTHS_AT } from '../constants/income';
import { UserIncomesDto } from '../dto/UserIncomesDto';
import { EmploymentTypeEnum } from '../enums/EmploymentTypeEnum';
import { IncomeFrequencyEnum } from '../enums/IncomeFrequencyEnum';
import { ApplicantState } from '../types/ApplicantState';
import { incomeFrequencyToAPI } from './incomeFrequencyToAPI';

export const toUserIncomesDto = (applicant: ApplicantState): UserIncomesDto => {
  const userIncomesDto: UserIncomesDto = {
    additional: null,
    current: {
      amount: toNumber(applicant.currentIncomeGrossAnnual),
      employerName: applicant.currentIncomeEmployerName,
      employmentType: applicant.currentIncomeEmploymentType,
      incomeFrequency: incomeFrequencyToAPI(IncomeFrequencyEnum.ANNUALLY),
      jobTitle: applicant.currentIncomeJobTitle,
      monthsAt: toNumber(applicant.currentIncomeMonthsAt),
    },
    previous: null,
  };

  const shouldIncludeAdditional = every(
    [
      applicant.additionalIncomeType,
      applicant.additionalIncomeAmount,
      applicant.additionalIncomeFrequency,
    ],
    isNonEmptyString,
  );

  if (shouldIncludeAdditional) {
    userIncomesDto.additional = {
      additionalIncomeType: applicant.additionalIncomeType,
      amount: toNumber(applicant.additionalIncomeAmount),
      employerName: null,
      employmentType: EmploymentTypeEnum.OTHER,
      incomeFrequency: incomeFrequencyToAPI(
        applicant.additionalIncomeFrequency,
      ),
      jobTitle: null,
      monthsAt: 0,
    };
  }

  const shouldIncludePrevious = every(
    [
      applicant.previousIncomeGrossAnnual,
      applicant.previousIncomeEmploymentType,
    ],
    isNonEmptyString,
  );

  if (shouldIncludePrevious) {
    userIncomesDto.previous = {
      amount: toNumber(applicant.previousIncomeGrossAnnual),
      employerName: applicant.previousIncomeEmployerName,
      employmentType: applicant.previousIncomeEmploymentType,
      incomeFrequency: incomeFrequencyToAPI(IncomeFrequencyEnum.ANNUALLY),
      jobTitle: applicant.previousIncomeJobTitle,
      monthsAt: DEFAULT_PREVIOUS_INCOME_MONTHS_AT,
    };
  }

  return userIncomesDto;
};

import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useAttributionStore from '../attributionStore/useAttributionStore';
import useParameterStore from '../parameterStore/useParameterStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { UserState } from './types/UserState';
import { userStore } from './userStore';

const useUserStore: DevtoolsStore<UserState> = create({
  hmrPersist: 'userStore',
})(
  devtools<UserState>(
    userStore({
      useApiStore: lazyDependency(() => useApiStore),
      useAttributionStore: lazyDependency(() => useAttributionStore),
      useParameterStore: lazyDependency(() => useParameterStore),
    }),
    devToolOptions('userStore'),
  ),
);

export default useUserStore;
